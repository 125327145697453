import React, {ReactNode, useEffect, useState} from "react";
import {useQuery, useQueryClient} from "react-query";
import {useSearchParams} from "react-router-dom";
import {useGetCentreActuel, useGetOneCentre} from "../features/centre/centre.hooks";
import {useGetActeById} from "../features/acte/acte.hooks";
import DocImg from "../assets/images/icon_doc384x.png";
import XlsImg from "../assets/images/icon_xls384x.png";
import PdfImg from "../assets/images/icon_pdf384x.png";
import IllImg from "../assets/images/icon_img384x.png";



export const useUserConnected = ()=>{
    const queryClient = useQueryClient();
    const userConnected:UserSimple|undefined =  queryClient.getQueryData("user_connected");
    return {userConnected}
}
export const useGetCentreParams = (idCentre:number|null=null, idParams:number)=>{
    const queryClient = useQueryClient();
    const CentreQuery = useGetOneCentre(idCentre||-1)
    const userConnected:UserSimple|undefined =  queryClient.getQueryData("user_connected");
    if(userConnected && !idCentre){
        const Centre = userConnected.Centre;
        const CentreParameters = Centre.parameters;
        const ParametersColor = CentreParameters.find(cp=>cp.Parameter.id === idParams);
        if(ParametersColor){
            return ParametersColor.statut;
        } else {
            return false;
        }
    } else if(idCentre && CentreQuery.data){
        const Centre = CentreQuery.data;
        const CentreParameters = Centre.parameters;
        const ParametersColor = CentreParameters.find(cp=>cp.Parameter.id === idParams);
        if(ParametersColor){
            return ParametersColor.statut;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const useGetCentreActeColor = (idCentre:number|null=null)=>{
    const queryClient = useQueryClient();
    const CentreQuery = useGetOneCentre(idCentre||-1)
    const userConnected:UserSimple|undefined =  queryClient.getQueryData("user_connected");
    if(userConnected && !idCentre){
        const Centre = userConnected.Centre;
        const CentreParameters = Centre.parameters;
        const ParametersColor = CentreParameters.find(cp=>cp.Parameter.id === 7);
        if(ParametersColor){
            return ParametersColor.statut;
        } else {
            return false;
        }
    } else if(idCentre && CentreQuery.data){
        const Centre = CentreQuery.data;
        const CentreParameters = Centre.parameters;
        const ParametersColor = CentreParameters.find(cp=>cp.Parameter.id === 7);
        if(ParametersColor){
            return ParametersColor.statut;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const useGetActeCreneauCentre = ()=>{
    const CentreQuery = useGetCentreActuel();
    const [acte, setActe] = useState<Acte|null>(null);
    const [idActe, setIdActe] = useState<number>(0)
    const ActeQuery = useGetActeById(idActe);
    const [loading, setLoading] = useState(true);
    useEffect(()=>{
        if(CentreQuery.data) {
            const litts: any = CentreQuery.data.literalParameters;
            const idActe = litts.hasOwnProperty('idActeCreneau') ? litts.idActeCreneau : 0;
            setIdActe(idActe);
        }
    }, [CentreQuery.data])
    useEffect(()=>{
        if(ActeQuery.data){
            setActe(ActeQuery.data);
            setLoading(false);
        }
    }, [ActeQuery.data])
    return {loading, acte}
}

export const useGetCentreDayOff = (idCentre:number|null=null)=>{
    const CentreQuery = useGetCentreActuel();
    const CentreQQuery = useGetOneCentre(idCentre||-1)
    const [numDayOffCentre, setNumDayOffCentre] = useState<number[]>([])
    const TabJT:{lib:string, open:boolean, numDay:number}[]=[
        {lib:'L', open:false, numDay:1},
        {lib:'M', open:false, numDay:2},
        {lib:'Me', open:true, numDay:3},
        {lib:'J', open:true, numDay:4},
        {lib:'V', open:true, numDay:5},
        {lib:'S', open:true, numDay:6},
        {lib:'D', open:false, numDay:0},
    ]
    useEffect(()=>{
        if(CentreQuery.data && !idCentre){
            const litts:any  = CentreQuery.data.literalParameters;
            const Opens = litts.hasOwnProperty('opens') ? litts.opens : null;
            let MyTabJ:number[] = [];
            if(Opens){
                TabJT.map(j=>{
                    if(Opens[j.lib] === 0){
                        MyTabJ.push(j.numDay);
                    }
                })
                setNumDayOffCentre(MyTabJ);
            }
        } else if(CentreQQuery.data && idCentre){
            const litts:any  = CentreQQuery.data.literalParameters;
            const Opens = litts.hasOwnProperty('opens') ? litts.opens : null;
            let MyTabJ:number[] = [];
            if(Opens){
                TabJT.map(j=>{
                    if(Opens[j.lib] === 0){
                        MyTabJ.push(j.numDay);
                    }
                })
                setNumDayOffCentre(MyTabJ);
            }
        }
    }, [CentreQuery.data, CentreQQuery.data])

    return {isLoading:CentreQuery.isLoading , days:numDayOffCentre};
}

export const useModal = ()=>{
    const [show, setShow] = useState(false);
    const [waiting, setWaiting] = useState(false);

    function toggle() {
        setShow(show=>!show);
    }
    function waitForIt(){

        setWaiting(waiting=>!waiting);
    }
    return {show,toggle, waiting, waitForIt};
}
export const useGetWeekDate = (dat:string|null)=>{
    const [dateStart, setDateStart] = useState<Date|null>(null);
    const [dateEnd, setDateEnd] = useState<Date|null>(null);
    const [isCalculate, setIsCalculate] = useState(true);
    useEffect(()=>{
        setIsCalculate(true);
        if(dat!==null){
            const DatDat = new Date(dat);
            const numDayDate = DatDat.getDay() === 0 ? 6 : DatDat.getDay()-1;
            const DateStart = new Date(DatDat.getTime() - (numDayDate*86400000));
            const DateEnd = new Date(DateStart.getTime() + (6*86400000));
            setDateStart(DateStart);
            setDateEnd(DateEnd);
            setIsCalculate(false);
        }
    }, [dat])
    return {isCalculate, dateStart, dateEnd};
}


export function useDebounce<T>(value: T, delay: number): T {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState<T>(value);

    useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay] // Only re-call effect if value or delay changes
    );

    return debouncedValue;
}

export const useSetSmartNavBar = ()=>{
    const [searchParams, setSearchParams] = useSearchParams();
    const setParams = (vals:{key:string, value:string|number}[])=>{
        const TabParams = [...Array.from(searchParams)];
        let myParams: any = {
            from:  '',
            to:  '',
            lieu:  '',
            fx:'',
            idCollab:'',
            vues:'',
            index: '0'
        };
        TabParams.map(item=>{
            const Find = vals.find(i=>i.key === item[0]);
            if(!Find){
                myParams[item[0]] = item[1];
            }
        })
        for (const [k,v] of Object.entries(myParams)){
            const Find = vals.find(i=>i.key === k);
            if(Find){
                if(Find.value === ''){
                    delete myParams[k];
                } else {
                    myParams[k] = Find.value
                }
            } else if(v===''){
                delete myParams[k];
            }
        }
        setSearchParams(myParams);
    }
    return {setParams}
}

function getProfileData() {
    return localStorage.getItem('__centre_connected__') ? JSON.parse(localStorage.getItem('__centre_connected__')||'') : '0';
}

export function useCentreConnectedData() {
    const [idcentre, setidCentre] = useState<number|null>(null);
    useEffect(() => {
        setidCentre(parseInt(getProfileData()));
        function handleChangeStorage() {
            setidCentre(parseInt(getProfileData()));
        }

        window.addEventListener('storage', handleChangeStorage);
        return () => window.removeEventListener('storage', handleChangeStorage);
    }, []);

    return idcentre;
}

export const useGetParams = ()=>{
    const [searchParams, setSearchParams] = useSearchParams();
    const [TabFrom, setTabFrom] = useState<string|undefined>(undefined);
    const [TabTo, setTabTo] = useState<string|undefined>(undefined);
    const [TabLieu, setTabLieu] = useState<string|undefined>(undefined);
    const [TabFx, setTabFx] = useState<string|undefined>(undefined);
    const [TabCollab, setTabCollab] = useState<string|undefined>(undefined);
    const [TabVues, setTabVues] = useState<string|undefined>(undefined);
    useEffect(()=>{
        const TabParamsB = [...Array.from(searchParams)];
        const TabFromx = TabParamsB.find(tab => tab[0] === 'from');
        const TabTox = TabParamsB.find(tab => tab[0] === 'to');
        const TabLieux = TabParamsB.find(tab => tab[0] === 'lieu');
        const TabFxx = TabParamsB.find(tab => tab[0] === 'fx');
        const TabCollabx = TabParamsB.find(tab => tab[0] === 'idCollab');
        const TabVuesx = TabParamsB.find(tab=>tab[0]==='vues');
        setTabFrom(TabFromx ? TabFromx[1] : undefined)
        setTabTo(TabTox ? TabTox[1] : undefined)
        setTabLieu(TabLieux ? TabLieux[1] : undefined)
        setTabFx(TabFxx ? TabFxx[1] : undefined)
        setTabCollab(TabCollabx ? TabCollabx[1] : undefined)
        setTabVues(TabVuesx ? TabVuesx[1] : undefined)
    }, [searchParams])
    return {TabFrom, TabTo, TabFx, TabLieu, TabVues, TabCollab}
}

export const useEventSourceQuery = (
    url: URL,
    topics: string[],
    eventCallback: {
        eventName: "message" | "open" | "error";
        callback: (event: MessageEvent) => any;
    },
    queryKey?: any
) => {
    for (const topic of topics) {
        url.searchParams.append("topic", topic);
    }

    const fetchData = () => {
        const evtSource = new EventSource(url);
        let data = {
            data: "Aucune donnée n'a été renvoyée par la callback function",
        };

        evtSource.addEventListener(eventCallback.eventName as string, (event) => {
            data = eventCallback.callback(event);
        });

        return data;
    };

    return useQuery(queryKey, fetchData);
};

export const useTimedSortable = (defaultChoice:Choice, sensDefault:1|-1=-1)=>{
    const [sortSens, setSortSens] = useState<1|-1>(sensDefault)
    const [filterSort, setFilterSort] = useState<Choice>(defaultChoice)
    const handleChangeSort = (chx:Choice)=>{
        if(chx.id === filterSort.id){
            setSortSens(s=>s === 1 ? -1 : 1)
        } else{
            setFilterSort(chx)
        }
    }
    return {sortSens, handleChangeSort, filterSort}
}

export const useGetImageDocument = (ext:string|null)=>{
    const TabWord = ['docx', 'doc', 'odt'];
    const TabExcel = ['xlsx', 'xls', 'xl'];
    const TabPDF = ['PDF', 'pdf'];
    const TabImg = ['png', 'jpg', 'jpeg', 'JPEG']
    const [myIcon, setMyIcon] = useState<ReactNode|null>(null)
    const getIcon = (ext:string)=>{
        if(TabWord.indexOf(ext)!==-1){
            return <img src={DocImg} alt={"icon_doc"}/>;
        } else if(TabExcel.indexOf(ext)!==-1){
            return <img src={XlsImg} alt={"icon_xls"}/>;
        } else if(TabPDF.indexOf(ext)!==-1){
            return <img src={PdfImg} alt={"icon_pdf"}/>;
        } else if(TabImg.indexOf(ext)!==-1){
            return <img src={IllImg} alt={"icon_image"}/>;
        } else {
            return <img src={DocImg} alt={"icon_doc"}/>;
        }
    }
    useEffect(() => {
        if(!ext){
            setMyIcon(null);
        } else {
            setMyIcon(getIcon(ext))
        }
    }, [ext]);

    return {Image:myIcon};
}

