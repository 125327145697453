import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {useGetAllTypeRapport} from "../../features/typeRapport/typeRapport.hooks";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {useAddExport} from "../../features/export/export.hooks";
import TimedInputTextH from "../../components/atoms/TimedInputText/TimedInputTextH";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import {MenuItem} from "@mui/material";
import TimedButton from "../../components/atoms/TimedButton/TimedButton";

interface FormNewExportCtrlProps{
    className?:string;
}

const FormNewExportCtrl = ({className}:FormNewExportCtrlProps)=>{
    const mutation = useAddExport();
    const [dateR, setDateR] = useState(false);
    const TypesRapportQuery = useGetAllTypeRapport();
    const defaultData = {
        fileName:'',
        TypeRapport:'',
        dateStart:'',
        dateEnd:'',
    }
    const methods = useForm({
        mode:'onChange',
        defaultValues:defaultData
    })

    const watchType = methods.watch("TypeRapport");
    useEffect(()=>{
        if(watchType === "Export Base Utilisateur"){
            setDateR(false);
        } else {
            setDateR(true);
        }
    }, [watchType])
    const onSubmit = (data:any)=>{
        const myTypeR = TypesRapportQuery.data?.find(tr=>tr.libelle === data.TypeRapport);
        if(myTypeR){
            let myDatas:ExportNewFD={
                FileName:data.fileName,
                TypeRapport:`/api/type_rapports/${myTypeR.id}`
            }
            if(data.dateStart !== ''){
                myDatas.dateStartAt = data.dateStart;
            }
            if(data.dateEnd !== ''){
                myDatas.dateEndAt = data.dateEnd;
            }
            mutation.mutate((myDatas))
        }
    }

    return (
        <div className={`form-new-export ${className}`}>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className={"part-form"}>
                        <TimedInputTextH
                            label={"Nom du fichier"}
                            type={"text"}
                            isRequired={true}
                            register={methods.register}
                            step={0.5}
                            optionRegister={{
                                required: {
                                    value: true,
                                    message: "Nom requis",
                                },
                            }}
                            getValue={methods.getValues}
                            id={"fileName"}
                            size={"small"}
                            isError={!!methods.formState.errors.fileName}
                            helperText={methods.formState.errors.fileName ? methods.formState.errors.fileName.message : ""}
                            isFull={true}
                        />
                    </div>
                    <div className={"part-form"}>
                        <Controller
                            name="TypeRapport"
                            control={methods.control}
                            render={({ field }) => <>
                                <FormControl sx={{ m: 1, minWidth: 120 }} size="small" fullWidth={true}>
                                    <InputLabel id="label-acte">Type*</InputLabel>
                                    <Select
                                        label="Type*"
                                        {...field}

                                    >
                                        <MenuItem value={""}>Choix du type</MenuItem>
                                        {TypesRapportQuery.data?.map((Item:TypeRapport, idx:number)=>(
                                            <MenuItem value={Item.libelle} key={`oneType${idx}`}>{Item.libelle}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </>}
                        />
                        {!!methods.formState.errors.TypeRapport && <div className={"helper-text"}>Champ obligatoire</div>}
                    </div>
                    {dateR &&
                        <>
                            <div className={"part-form"}>
                                <TimedInputTextH
                                    label={"Date Départ"}
                                    type={"date"}
                                    isRequired={true}
                                    register={methods.register}
                                    optionRegister={{required: false}}
                                    getValue={methods.getValues}
                                    id={"dateStart"}
                                    size={"small"}
                                    isError={!!methods.formState.errors.dateStart}
                                    helperText={methods.formState.errors.dateStart ? methods.formState.errors.dateStart.message : ""}
                                    isFull={true}
                                />
                            </div>
                            <div className={"part-form"}>
                                <TimedInputTextH
                                    label={"Date Fin"}
                                    type={"date"}
                                    isRequired={true}
                                    register={methods.register}
                                    optionRegister={{required: false}}
                                    getValue={methods.getValues}
                                    id={"dateEnd"}
                                    size={"small"}
                                    isError={!!methods.formState.errors.dateEnd}
                                    helperText={methods.formState.errors.dateEnd ? methods.formState.errors.dateEnd.message : ""}
                                    isFull={true}
                                />
                            </div>
                        </>
                    }
                    <div className={`wrap-action`}>
                        <TimedButton
                            themeColor={"Primary"}
                            size={"sm"}
                            text={"enregistrer"}
                            type={"submit"}
                            disabled={mutation.isLoading}
                            isPending={mutation.isLoading}

                        />
                    </div>
                </form>
            </FormProvider>
        </div>
    )
}

export const FormNewExport = styled(FormNewExportCtrl)`
  margin: 20px auto;
    form{
      display: flex;
      justify-content: flex-start;
      gap: 10px;
      align-items: center;
      .wrap-action{
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
      }
      .part-form {
        flex-grow: 1;
      }
    }
`