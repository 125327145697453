import styled from "styled-components";
import {OneItemProj} from "../ProjectionST";
import React from "react";
import AvatAno from "../../../../../assets/images/Unisex-Person-with-Glasses.png";
import {useGetCentreActeColor} from "../../../../../services/hooks";

interface ProjItemCtrlProps {
    className?:string;
    ProjItem:OneItemProj;
}

const ProjItemCtrl = ({className, ProjItem}: ProjItemCtrlProps) => {
    const isActeColor = useGetCentreActeColor()
    const handleError = (e:any)=>{
        e.target.src = AvatAno;
    }
    return (
        <div className={`one_proj_item ${className}`}>
            <div className="in_item" style={{background:isActeColor ? ProjItem.Acte.backColor : ProjItem.Lieu.backColor, color:isActeColor ? ProjItem.Acte.fontColor : ProjItem.Lieu.fontColor}}>
                <div className={`Avatar_style`} style={{width:"35px", height:"24px", background:isActeColor ? ProjItem.Acte.backColor : ProjItem.Lieu.backColor}}>
                    <img src={`${process.env.REACT_APP_API_URL_ROOT}${ProjItem.user.avatarUrl}`}
                         onError={handleError}
                         alt={'avatar user'}
                    />
                </div>
                <div className="name">
                    <span>{ProjItem.user.prenom.toUpperCase().slice(0,1)}.{ProjItem.user.nom.toUpperCase()}</span>
                </div>
            </div>
        </div>
    )
}

export const ProjItem = styled(ProjItemCtrl)`
    padding: 0.1rem;
    
    width: 100%;
    .name{
        flex-grow: 1;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
        overflow: hidden;
        font-size: 12px;
        span{
            font-weight: bold;
        }
    }
    .in_item{
        width: 100%;
        display: flex;
        gap: 0.25rem;
        align-items: center;
        font-size: 12px;
        border:solid 1px ${props=>props.theme.Primary};
        border-radius: 4px;
    }
    .Avatar_style {
        border: solid ${props => props.theme.NeutreLight} 1px;
        border-radius: 4px;
        background: #ccc;
        font-size: 12px;
        font-weight: bold;
        //width: 24px;
        //height: 24px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        overflow: hidden;
        position: relative;

        img {
            width: 24px;
            height: 24px;
            margin-left: 7px;
        }
        
    }
`