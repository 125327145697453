import React, {useMemo} from "react";
import styled from "styled-components";
import { TitrePage } from "../../../../assets/styles/elements";
import {FormNewExport} from "../../../Forms/FormNewExport";
import {useGetAllExports, useTrashExport} from "../../../../features/export/export.hooks";
import TimedSkeletonList from "../../../../components/atoms/TimedSkeleton/TimedSkeletonList";
import TimedIconButton from "../../../../components/atoms/TimedIconButton/TimedIconButton";
import { AiOutlineDownload } from "react-icons/ai";
import {BsTrash} from "react-icons/bs";
import {fileFetcher} from "../../../../services/dataGuetter/dataFetcher";
import {useCentreConnectedData} from "../../../../services/hooks";

interface ExportsCtrlProps{
    className?:string;
    supervisor?:boolean;
}

const ExportsCtrl = ({className, supervisor}:ExportsCtrlProps)=>{
    const centre = useCentreConnectedData();
    const ExportsQuery = useGetAllExports(supervisor ? centre : null);
    const mutationTrash = useTrashExport();
    const ExportsDownloadable = useMemo(()=>{
        let list:Export[] = [];
        if(ExportsQuery.data){
            list = ExportsQuery.data.filter(e=>e.endAt !== null).sort((a:Export, b:Export)=>new Date(a.askAt) > new Date(b.askAt) ? -1 : 1);
        }
        return list;
    }, [ExportsQuery.data])
    const ExportsEnCours = useMemo(()=>{
        let list:Export[] = [];
        if(ExportsQuery.data){
            list = ExportsQuery.data.filter(e=>e.endAt === null && !e.isError);
        }
        return list;
    }, [ExportsQuery.data])
    const ExportsErrors = useMemo(()=>{
        let list:Export[] = [];
        if(ExportsQuery.data){
            list = ExportsQuery.data.filter(e=>e.isError === true);
        }
        return list;
    }, [ExportsQuery.data])
    const handleClickDown = (Export:Export)=>{
        if(Export.FileUrl){
            const ext = Export.TypeRapport.extension ? Export.TypeRapport.extension : 'csv';
            fileFetcher(Export.FileUrl, Export.FileName+'.'+ext);
        }
            // window.open(`${process.env.REACT_APP_API_URL_ROOT}${Export.FileUrl}`);
    }
    const handleClickTrash = (Export:Export)=>{
        mutationTrash.mutate((Export.id));
    }
    return (
        <div className={`Export ${className}`}>
            <TitrePage>Exports et Rapports</TitrePage>
            <h2>Nouvel export</h2>
            <FormNewExport/>
            <h2>Liste des exports</h2>
            <div className={`wrap-res-export`}>
                {ExportsQuery.isLoading ?
                    <TimedSkeletonList qty={3} nbBar={4}/>:
                    <>
                        <div className={"one-block-exports"}>
                            <h3>Exports téléchargeable</h3>
                            <div className={`wrap-exports`}>
                                {ExportsDownloadable.length === 0 ?
                                    <p>Aucun</p>:
                                    ExportsDownloadable.map((item:Export, idx:number)=>(
                                        <div className={`one-export-down card-export`} key={`oneExD${idx}`}>
                                            <div className={`Type-place`}>
                                                <span className={`lib-infos`}>Type d'export</span>
                                                <span className={`info`}>{item.TypeRapport.libelle}</span>
                                            </div>
                                            <div className={"name-file-place"}>
                                                <span className={`lib-infos`}>Nom du fichier</span>
                                                <span className={`info`}>{item.FileName}</span>
                                            </div>
                                            <div className={"ask-place"}>
                                                <span className={`lib-infos`}>Date de la demande</span>
                                                <span className={`info`}>{`${new Date(item.askAt).toLocaleDateString()} ${new Date(item.askAt).toLocaleTimeString()}`}</span>
                                            </div>
                                            <div className={"end-place"}>
                                                <span className={`lib-infos`}>Fini le </span>
                                                <span className={`info`}>{item.endAt && `${new Date(item.endAt).toLocaleDateString()} ${new Date(item.endAt).toLocaleTimeString()}`}</span>
                                            </div>
                                            <div className={"Download-place"}>
                                                <TimedIconButton
                                                    size={"medium"}
                                                    themeColor={"Complementary"}
                                                    Icon={<BsTrash/>}
                                                    toolTip={"supprimer"}
                                                    onClick={()=>handleClickTrash(item)}
                                                    BackAlw={true}
                                                    isActive={!mutationTrash.isLoading}
                                                    isPending={mutationTrash.isLoading}
                                                    isSquare={true}
                                                />
                                                <TimedIconButton
                                                    size={"medium"}
                                                    themeColor={"Primary"}
                                                    Icon={<AiOutlineDownload/>}
                                                    toolTip={"télécharger"}
                                                    onClick={()=>handleClickDown(item)}
                                                    BackAlw={true}
                                                    isActive={true}
                                                    isSquare={true}
                                                />
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className={"one-block-exports"}>
                            <h3>Exports en cours</h3>
                            <div className={`wrap-exports`}>
                                {ExportsEnCours.length === 0 ?
                                    <p>Aucun</p>:
                                    ExportsEnCours.map((item:Export, idx:number)=>(
                                        <div className={`one-export-load card-export`} key={`oneExEC${idx}`}>
                                            <div className={`Type-place`}>
                                                <span className={`lib-infos`}>Type d'export</span>
                                                <span className={`info`}>{item.TypeRapport.libelle}</span>
                                            </div>
                                            <div className={"name-file-place"}>
                                                <span className={`lib-infos`}>Nom du fichier</span>
                                                <span className={`info`}>{item.FileName}</span>
                                            </div>
                                            <div className={"ask-place"}>
                                                <span className={`lib-infos`}>Date de la demande</span>
                                                <span className={`info`}>{new Date(item.askAt).toLocaleDateString()}</span>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        {ExportsErrors.length > 0 &&
                            <div className={"one-block-exports"}>
                                <h3>Exports en erreurs</h3>
                                <div className={`wrap-exports`}>
                                    {ExportsErrors.length === 0 ?
                                        <p>Aucun</p>:
                                        ExportsErrors.map((item:Export, idx:number)=>(
                                            <div className={`one-export-error card-export`} key={`oneExErr${idx}`}>
                                                <div className={`Type-place`}>
                                                    <span className={`lib-infos`}>Type d'export</span>
                                                    <span className={`info`}>{item.TypeRapport.libelle}</span>
                                                </div>
                                                <div className={"name-file-place"}>
                                                    <span className={`lib-infos`}>Nom du fichier</span>
                                                    <span className={`info`}>{item.FileName}</span>
                                                </div>
                                                <div className={"ask-place"}>
                                                    <span className={`lib-infos`}>Date de la demande</span>
                                                    <span className={`info`}>{new Date(item.askAt).toLocaleDateString()}</span>
                                                </div>
                                                <div className={"Download-place"}>
                                                    <TimedIconButton
                                                        size={"medium"}
                                                        themeColor={"Complementary"}
                                                        Icon={<BsTrash/>}
                                                        toolTip={"supprimer"}
                                                        onClick={()=>handleClickTrash(item)}
                                                        BackAlw={true}
                                                        isActive={!mutationTrash.isLoading}
                                                        isPending={mutationTrash.isLoading}
                                                        isSquare={true}
                                                    />
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        }
                    </>
                }
            </div>
        </div>
    )
}

const Exports = styled(ExportsCtrl)`
    padding: 1rem;
  h2{
    font-size: 18px;
  }
  h3{
    font-size: 16px;
    margin: 20px auto 15px;
  }
  .lib-infos{
      color:${props => props.theme.Primary};
    
  }
  .info{
      font-weight: bold;
      display: block;
  }
  .Type-place{
    width: 22%;
    padding: 0 0.25rem;
  }
  .name-file-place, .ask-place, .end-place{
    padding: 0 0.25rem;
  }
  .Download-place{
    flex-grow: 1;
    gap: 10px;
    padding: 0 0.5rem;
    display: flex;
    justify-content: flex-end;
  }
  .one-export-down{
    border-left: solid 10px ${props=>props.theme.Secondary};
  }
  .one-export-load{
    border-left: solid 10px ${props=>props.theme.NeutreDark};
  }
  .one-export-error{
    border-left: solid 10px ${props=>props.theme.Complementary};
  }
  .card-export{
    display: flex;
    padding: 1rem;
    background: white;
    box-shadow: 1px 1px 3px rgba(0,0,0,.2);
    font-size: 14px;
    gap: 25px;
    border-radius: 6px;
    margin-bottom: 10px;
  }
`

export default Exports;