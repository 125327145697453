import React, {useEffect, useMemo, useRef, useState} from "react";
import styled from "styled-components";
import {BiChevronLeft, BiChevronRight} from "react-icons/bi";
import {GetWeeksNum, OneWeekEleven} from "../../functions/TimesFunctions";

const months = [
    {lib:'Janvier', num:1},
    {lib:'Février', num:2},
    {lib:'Mars', num:3},
    {lib:'Avril', num:4},
    {lib:'Mai', num:5},
    {lib:'Juin', num:6},
    {lib:'Juillet', num:7},
    {lib:'Août', num:8},
    {lib:'Septembre', num:9},
    {lib:'Octobre', num:10},
    {lib:'Novembre', num:11},
    {lib:'Decembre', num:12},
]
const weekdayshort = ['L', 'M', 'Me', 'J', 'V', 'S', 'D'];
interface TimedDatePickerCtrlProps{
    className?:string;
    dateRef:Date;
    dateChoice:Date|null;
    dateRefDep?:Date|null;
    dateForbids?:string[];
    size:"small" | "medium" | "large" | "xl";
    themeColor?:string;
    onPick?:Function;
    handlePickWeek:Function
}

interface MonthContentCtrlProps{
    className?:string;
    dateRef:Date;
    dateForbids?:string[];
    dateChoix:Date|null;
    setDateChoix:Function;
    size?:string;
    dateRefDep?:Date|null;
    heightDay?:string;
    handlePickWeek:Function;
}



const MonthContentCtrl = ({className, dateRef, dateForbids, dateChoix, setDateChoix,dateRefDep, handlePickWeek}:MonthContentCtrlProps)=>{
    const DatesForbidsDate = !dateForbids ? [] : dateForbids.map(dateString=>new Date((new Date(dateString)).setHours(12,0,0)));
    const daysShorts = weekdayshort.map((item, idx)=>{
        return <div className="headDays" key={`head${idx}`}><div className={"cell-cal-head"}>{item}</div></div>
    })
    const month = dateRef.getMonth()+1;
    const year = dateRef.getFullYear();
    const first_date_month = new Date(`${year}-${("0"+month).slice(-2)}-01T12:00:00`);
    let Clony = first_date_month;
    const yearNext = month===12 ? year+1 : year;
    const monthNext = month===12 ? 1 : month+1;
    const first_date_next_month = new Date(`${yearNext}-${("0"+monthNext).slice(-2)}-01T12:00:00`);
    const last_date_month = new Date(first_date_next_month.getTime() - 86400*1000);
    const dayLDM = last_date_month.getDay() !==0 ? last_date_month.getDay() : 7;
    const diffDayLast = 7 -dayLDM;
    let lasts = [];
    for(let i=1; i<=diffDayLast; i++){
        const DateChoixMid = dateChoix ? new Date(dateChoix.setHours(12,0,0)) : new Date('1970-01-01');
        const DateDepMid = dateRefDep ? new Date(dateRefDep.setHours(12,0,0)) : new Date('1970-01-01');
        const dateBefore = new Date(last_date_month.getTime() + (i)*(86400*1000))
        const DateIsForbs = DatesForbidsDate.find(d=>d.toString() === dateBefore.toString());
        const isForb = !!DateIsForbs;
        const isChoice = dateBefore.toString() === DateChoixMid.toString();
        const isDep = dateRefDep && dateBefore.toString() === DateDepMid.toString();
        const isBeetween = dateRefDep && dateBefore>DateDepMid && dateBefore<DateChoixMid;
        const yearDate = dateBefore.getFullYear();
        const monthDate = dateBefore.getMonth()<10 ? `0${dateBefore.getMonth()}` : `${dateBefore.getMonth()}`;
        const dayDate = dateBefore.getDate()<10 ? `0${dateBefore.getDate()}` : `${dateBefore.getDate()}`;
        console.log(dateBefore);
        lasts.push({date:dateBefore, d:dateBefore.getDate(), isForb:isForb, isChoice:isChoice, isDep:isDep, isBeetween:isBeetween, dateStr:`${yearDate}-${monthDate}-${dayDate}`})
    }
    let nbDaysMonth = 0;
    while(Clony<new Date(dateRef.getFullYear(), dateRef.getMonth()+1, 1, 12, 0)){
        nbDaysMonth++;
        Clony = new Date(Clony.getTime() + (86400*1000));
    }
    if(nbDaysMonth>31){
        nbDaysMonth = 31;
    }
    const pdep = first_date_month.getDay() === 0 ? 7 : first_date_month.getDay();

    let blanks = [];
    for (let i=1; i<pdep; i++){
        const DateChoixMid = dateChoix ? new Date(dateChoix.setHours(12,0,0)) : new Date('1970-01-01');
        const DateDepMid = dateRefDep ? new Date(dateRefDep.setHours(12,0,0)) : new Date('1970-01-01');
        const dateBefore = new Date(first_date_month.getTime() - (pdep-i)*(86400*1000))
        const DateIsForbs = DatesForbidsDate.find(d=>d.toString() === dateBefore.toString());
        const isForb = !!DateIsForbs;
        const isChoice = dateBefore.toString() === DateChoixMid.toString();
        const yearDate = dateBefore.getFullYear();
        const monthDate = dateBefore.getMonth()<10 ? `0${dateBefore.getMonth()}` : `${dateBefore.getMonth()}`;
        const dayDate = dateBefore.getDate()<10 ? `0${dateBefore.getDate()}` : `${dateBefore.getDate()}`;
        const isDep = dateRefDep && dateBefore.toString() === DateDepMid.toString();
        const isBeetween = dateRefDep && dateBefore>DateDepMid && dateBefore<DateChoixMid;
        console.log("blanck")
        console.log(dateBefore)
        blanks.push({date:dateBefore, d:dateBefore.getDate(), isForb:isForb, isChoice:isChoice, isDep:isDep, isBeetween:isBeetween, dateStr:`${yearDate}-${monthDate}-${dayDate}`})
    }
    let days = [];
    for (let i=1; i<=nbDaysMonth; i++){
        const DateChoixMid = dateChoix ? new Date(dateChoix.setHours(12,0,0)) : new Date('1970-01-01');
        const DateDepMid = dateRefDep ? new Date(dateRefDep.setHours(12,0,0)) : new Date('1970-01-01');
        let d = i<10 ? `0${i}`: i;
        let dateTo = new Date(`${year}-${("0"+month).slice(-2)}-${d}T12:00:00`)
        dateTo.setHours(12,0,0);
        const DateIsForbs = DatesForbidsDate.filter(d=>d.toString() === dateTo.toString());
        const isForb = DateIsForbs.length>0;
        const isChoice = dateTo.toString() === DateChoixMid.toString();
        const dateStr = `${year}-${month<10 ? `0${month}` : `${month}`}-${d}`
        const isDep = dateRefDep && dateTo.toString() === DateDepMid.toString();
        const isBeetween = dateRefDep && dateTo>DateDepMid && dateTo<DateChoixMid;
        console.log("beetween")
        console.log(dateTo)
        let daty = {date:dateTo, d:i, isForb:isForb, isChoice:isChoice, isDep:isDep, isBeetween:isBeetween, dateStr:dateStr}
        days.push(daty);
    }
    const handleClickDay = (item:any)=>{
        setDateChoix(new Date(item.date.setHours(12,0,0)));

    }
    return (
        <div className={`MonthContent ${className}`}>
            <div className={"wrap-wrap-head"}>
                <div className={`num-weeks`}></div>
                <div className="wrap-head">
                    {daysShorts}
                </div>
            </div>
            <div className="in-days">
                <div className={`wrap-in-days`}>
                    <div className={`num-weeks`}>
                        {GetWeeksNum(dateRef).map((item:OneWeekEleven, idx:number)=>(
                            <div className={`oneNumWeek`} key={`oneNumWeek${idx}`} onClick={()=>handlePickWeek(item)}>{`S${item.numSem}`}</div>
                        ))}
                    </div>
                    <div className={`days-months`}>
                        {blanks.map((item)=>(
                            <div key={`dayBefore`+item.d} className={`calendar-day other ${item.date.toLocaleDateString() === new Date().toLocaleDateString() ? 'isToday' : ''} ${(item.isChoice && dateRefDep) ? 'choice is-last' : item.isChoice ? 'choice' : ''} ${item.isForb ? 'forbid' : 'auth'} ${item.isDep ? 'choice is-first' : ''} ${item.isBeetween ? 'beetween' : ''}`}>
                                <div className={"cell-cal"} onClick={()=>handleClickDay(item)}>{item.d}</div>
                            </div>
                        ))}
                        {days.map((item)=>(
                            <div key={`day`+item.d} className={`calendar-day ${item.date.toLocaleDateString() === new Date().toLocaleDateString() ? 'isToday' : ''} ${(item.isChoice && dateRefDep) ? 'choice is-last' : item.isChoice ? 'choice' : ''} ${item.isForb ? 'forbid' : 'auth'} ${item.isDep ? 'choice is-first' : ''} ${item.isBeetween ? 'beetween' : ''}`}>
                                <div className={"cell-cal"} onClick={()=>handleClickDay(item)}>{item.d}</div>
                            </div>
                        ))}
                        {lasts.map((item)=>(
                            <div key={`dayAfter`+item.d} className={`calendar-day other last ${item.date.toLocaleDateString() === new Date().toLocaleDateString() ? 'isToday' : ''} ${(item.isChoice && dateRefDep) ? 'choice is-last' : item.isChoice ? 'choice' : ''} ${item.isForb ? 'forbid' : 'auth'} ${item.isDep ? 'choice is-first' : ''} ${item.isBeetween ? 'beetween' : ''}`}>
                                <div className={"cell-cal"} onClick={()=>handleClickDay(item)}>{item.d}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )

}
const MonthContent = styled(MonthContentCtrl)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: start;
  .num-weeks{
    display: flex;
    flex-direction: column;
    .oneNumWeek{
      width: 100%;
      height: ${props=>props.heightDay ? props.heightDay : 'auto'};
      color:${props=>props.theme.TertiaryLight};
      //flex-grow: 1;
      //text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 12px;
      &:hover{
        cursor: pointer;
      }
    }
  }
  .wrap-head {
    width: 100%;
    display: flex;
    
    flex-grow: 1;
    .headDays {
      width: 14.2857143%;
      padding: ${props => props.size === "small" ? '0.1rem' : props.size === "medium" ? '0.15rem' : '0.2rem'};

      .cell-cal-head {
        border-radius: 0.25rem;
        width: 100%;
        height: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color:${props=>props.theme.TertiaryLight}
      }
    }
  }

  .in-days {
    .days-months{
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    }
  }
  .wrap-wrap-head{
    display: flex;
    justify-content: start;
    height: 15%;
  }
  .num-weeks{
    width: 25px;
    flex-shrink: 0;
  }
  .wrap-in-days{
    display: flex;
    justify-content: start;
    .days-months{
      flex-grow: 1;
    }
  }

  .calendar-day {
      
    &.isToday{
      .cell-cal {
        border : solid ${props=>props.theme.TertiaryExtraDark} 1px;
      }
    }
    width: 14.2857143%;
    padding: ${props => props.size === "small" ? '0.1rem' : props.size === "medium" ? '0.15rem' : '0.2rem'};
    height: ${props=>props.heightDay ? props.heightDay : 'auto'};
    &:not(.forbid) .cell-cal:hover {
      cursor: pointer;
      background: ${props=>props.theme.TertiaryLight};
      color: white;
    }

    &.auth {
      .cell-cal {
        color: black;
      }
    }

    &.forbid {
      .cell-cal {
        background: #ed8181;
        color: white;
        text-decoration: line-through;
      }
    }

    &.beetween {
      background: ${props=>props.theme.TertiaryExtraLight};
      .cell-cal {
        background: ${props=>props.theme.TertiaryExtraLight};
        color: white;
      }
    }

    &.choice {
      &.is-last{
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
      }
      &.is-first{
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
      }
      background: ${props=>props.theme.TertiaryExtraLight};
      &.other{
        .cell-cal{
          color:white;
        }
      }
      .cell-cal {
        background: ${props=>props.theme.TertiaryLight};
        color: white;
      }
    }

    &.other {
      .cell-cal {
        color: #5e5e5e;
      }
    }

    .cell-cal {
      border-radius: 50%;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      background: white;

    }
  }
`

interface SetterCtrlProps{
    className?:string;
    item:string;
    list:any[];
    setItem:Function;
    libField:string;
    idField:string;
    themeColor?:string;
    size:"small" | "medium" | "large" | "xl";
}

const SetterCtrl = ({className, list, item, setItem, libField, idField}:SetterCtrlProps)=>{
    const [open, setOpen] = useState(false);
    const refObj = useRef<any>();
    useEffect(() => {
        function handleClickOutside(event:any) {
            if (refObj.current && !refObj.current.contains(event.target)) {
                setOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [refObj]);
    return (
        <div className={`Setter ${className}`}>
            <div className={`wrappy-setter`} ref={refObj}>
                <div className="wrap-item" onClick={()=>setOpen(o=>!o)}>{item}</div>
                <div className={`listChoice ${open ? 'opened' : ''}`}>
                    <ul>
                        {list.map((itemx, idx)=>(
                            <li key={`choice${idx}`} onClick={()=> {
                                setItem(itemx[idField]);
                                setOpen(false);
                            }}>{itemx[libField]}</li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}
const Setter = styled(SetterCtrl)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .wrappy-setter{
    width: 100%;
    position: relative;
    height: 100%;
    .wrap-item{
      width: 100%;
      height: 100%;
      background: white;
      text-align: center;
      padding: 0.25rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      &:hover{
        cursor: pointer;
        font-weight: 600;
      }
    }
    .listChoice{
      position: absolute;
      display: none;
      max-height: 150px;
      overflow-y: auto;
      width: max-content;
      &.opened{
        display: block;
      }
      left: 0;
      top: calc(100% + 4px);
      background: white;
      box-shadow: 1px 1px 3px rgba(0,0,0,.2);
      padding: ${props=>props.size === "medium" || props.size === "xl" ? "0.5rem 0.75rem" : "0.25rem 0.5rem"};
      ul{
        margin: 0;
        padding: 0;
        li{
          list-style: none;
          display: block;
          padding: 0.2rem;
          &:hover{
            cursor: pointer;
            background: ${props=>props.themeColor ? props.theme.Primary : '#ccc'};
          }
        }
      }
    }
    
  }
`

const TimedDatePickerCtrl = ({className, dateRef, dateForbids, onPick, size, dateRefDep, dateChoice, handlePickWeek}:TimedDatePickerCtrlProps)=>{
    const [daty, setDaty] = useState(dateRef);

    //const yearStart = dateRef.getFullYear();
    let yearsChoose = [];
    for(let i=0; i<15; i++){
        yearsChoose.push({lib:(2020+i), num:(2020+i)});
    }
    const month = useMemo(()=>{
        return daty.getMonth()+1;
    }, [daty])

    const year = useMemo(()=>{
        return daty.getFullYear()
    }, [daty])
    const HandleMove = (sens:any)=>{
        const newMonth = month+(1*sens) === 0 ? '12' : month+(1*sens)===13 ? '01' : `${month+(1*sens)}`;
        const newYear = month+(1*sens) === 0 ? year-1 : month+(1*sens)===13 ? year+1 : year;
        const newDaty = new Date(`${newYear}-${("0"+newMonth).slice(-2)}-01T12:00:00`);
        console.log(newDaty)
        setDaty(newDaty);
    }
    const HandleSetMonth = (Tmonth:string)=>{
        const newDaty = new Date(`${year}-${Tmonth}-01`);
        setDaty(newDaty);
    }
    const HandleSetYear = (Tyear:string)=>{
        const newDaty = new Date(`${Tyear}-${month}-01`);
        setDaty(newDaty);
    }
    const HandlePickDate = (date:Date)=>{
        if(onPick){
            console.log(date.toLocaleDateString());
            onPick(new Date(date.setHours(12,0,0)));
        }
    }
    return(
        <div className={`agendaPicker ${className}`}>
            <div className="Choose-time">
                <div className="Move"><div className="btn-move" onClick={()=>HandleMove(-1)}><BiChevronLeft/></div></div>
                <div className="wrapChooseTime">
                    <div className="wrap-month wrappy">
                        <Setter item={months[(month-1)].lib} setItem={HandleSetMonth} libField={"lib"} idField={"num"} list={months} size={size!==undefined ? size : "small"}/>
                    </div>
                    <div className="wrap-year wrappy">
                        <Setter item={year.toString()} setItem={HandleSetYear} libField={"lib"} idField={"num"} list={yearsChoose}  size={size}/>
                    </div>
                </div>
                <div className="Move"><div className="btn-move" onClick={()=>HandleMove(1)}><BiChevronRight/></div></div>
            </div>
            <MonthContent handlePickWeek={handlePickWeek} heightDay={"25px"} dateRef={daty} dateChoix={dateChoice} setDateChoix={HandlePickDate} dateForbids={dateForbids} size={size} dateRefDep={dateRefDep}/>
        </div>
    )
}

const TimedDatePicker = styled(TimedDatePickerCtrl)`
  margin: auto;
  width: ${props=>props.size === "small" ? "200px" : props.size === "medium" ? "300px" : "600px"};
  height: ${props=>props.size === "small" ? "238px" : props.size === "medium" ? "300px" : "600px"};
  font-size: ${props=>props.size === "small" ? "12px" : props.size === "medium" ? "16px" : "18px"};
  display: flex;
  flex-direction: column;
  justify-content: start;
    .Choose-time{
      height: 15%;
      width: 100%;
      display: flex;
      justify-content: start;
      align-items: stretch;
      .wrapChooseTime
      {
        flex-grow: 1;
        display: flex;
        .wrappy{
          width: 50%;
          height: 100%;
          padding: 0.25rem; 
        }
      }
      .Move{
        width: 15%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .btn-move{
          position: relative;
          width: 80%;
          margin: auto;
          padding-bottom: 80%;
          border-radius: 50%;
          &:hover{
            cursor: pointer;
            background: ${props=>props.theme.TertiaryExtraLight};
          }
          svg{
            display: block;
            font-size: ${props=>props.size==="small" ? "18px" : "22px"};
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
          }
        }
      }
    }
`

export default TimedDatePicker;