import styled from "styled-components";
import {OneSegmentationProj} from "../ProjectionST";
import {Mesures} from "../../PlanningGlobalEquipe/PlanningGlobalEquipe";
import React, {useEffect, useState} from "react";
import {PlByLieu} from "../../PlanningGlobalEquipe/components/PlByLieu";
import {PlEntry} from "../../PlanningGlobalEquipe/components/Objects/PlEntry";
import {ProjLieu} from "./ProjLieu";

interface ProjSegmentationCtrlProps {
    className?:string;
    ProjSeg:OneSegmentationProj;
    measures:Mesures
}

const ProjSegmentationCtrl = ({className, ProjSeg, measures}: ProjSegmentationCtrlProps) => {
    const [heightEntry, setHeightEntry] = useState(0)
    useEffect(()=>{
        const mys = document.getElementsByClassName(`proj_lieu_${ProjSeg.id}`)
        let heightTotal = 0;
        Array.from(mys).forEach(i=>{
            const elt = i.getBoundingClientRect();
            heightTotal+=elt.height
        })
        setHeightEntry(heightTotal)
    }, [])
    return (
        <div className={`one_pl_eq_seg ${className}`} >
            <PlEntry libelle={ProjSeg.libelle} wD={(measures.segmentations+10)+"px"} isGap padIn isRotate={true} wDNumber={measures.segmentations} height={heightEntry}>
                <div className="list_lieu_proj" style={{display:"flex", flexDirection:"column", gap:"4px"}}>
                    {ProjSeg.Lieux.map(item=>(
                        <ProjLieu
                            ProjLieu={item}
                            ProjSeg={ProjSeg}
                            key={`OneProjLieuSeg${ProjSeg.id}${item.Lieu.id}`}
                            measures={measures}
                        />
                    ))}
                </div>
            </PlEntry>
        </div>
    )
}

export const ProjSegmentation = styled(ProjSegmentationCtrl)`
    border: solid ${props => props.theme.NeutreExtraLight} 1px;
    &:not(:last-child){
        margin-bottom: 14px;
    }
`